import * as React from "react";
import { Link } from "gatsby";

import Page from "../components/Page";
import Container from "../components/Container";
import IndexLayout from "../layouts";

const NotFoundPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <h1>404: Page not found.</h1>
          <p>
            You've hit the void. <Link to="/">Go back.</Link>
          </p>
        </div>
      </Container>
    </Page>
  </IndexLayout>
);

export default NotFoundPage;
